.set-background-image {
  background-image: url('../brand/NewtonInsightsBranding/NIBackground.png');
  background-size: cover; /* This will make the image cover the entire container */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center center;

  /* Add other background image styling properties as needed */
}
.reg-main-header {
  font-family: 'Inter', sans-serif;
  font-weight: 500; /* Medium font weight */
  font-size: 32px;
  color: grey;
  text-align: center;
  font-style: italic;
}

.reg-image-container {
  padding: 5px;
  width: 100%;  /* Adjust the width as needed */
  height: 50%; /* Adjust the height as needed */
  overflow: hidden; /* This hides any part of the image that exceeds the container */
  /* display: flex; Helps center the image inside the container */
  justify-content: right; /* Horizontally center the image */
  align-items: center; /* Vertically center the image */
  /* margin-left: 130px; */
}
.reg-image-container img {
  max-width: 100%;
  max-height: 100%;
  /* height: auto; Maintain aspect ratio */
  /* width: auto;  Maintain aspect ratio */
}

.reg-company-name {
  font-family: 'Inter', sans-serif;
  font-weight: 500; /* Medium font weight */
  font-size: 20px;
  color: black;
  text-align: center;
  margin-right: 100px;
  margin-top: 30px;
  /* position: fixed;
  bottom: 10px; */
  font-style: italic; 
}

.login-link {
  font-family: 'Bio Sans', sans-serif;
  font-size: 18px;
  color: #8898a7;
  margin-top: 10px;
  margin-left: 15px;
  font-style: italic;
  border: none; /* Remove the border */
  background-color: transparent; /* Remove the background */
  text-decoration: underline; /* Add underline */
}

/* CSS class for elements that should be hidden on small screens and visible on laptop screens */

/* First, hide the element by default */
.hidden-on-small {
  display: none;
}

/* Then, use a media query to show it on screens wider than 1024px */
@media (min-width: 1024px) {
  .hidden-on-small {
      display: block; /* or 'flex', 'inline', 'inline-block', etc., depending on your layout needs */
  }
}

@media (max-width: 600px) {
  .reg-image-container img {
    margin-left: 30px;
  }
}