.set-background-image {
  background-image: url('../brand/NewtonInsightsBranding/NIBackground.png');
  background-size: cover; /* This will make the image cover the entire container */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center center;

  /* Add other background image styling properties as needed */
}

.main-header {
  font-family: 'Inter', sans-serif;
  font-weight: 500; /* Medium font weight */
  font-size: 32px;
  color: black;
  text-align: center;
}
.sign-in-text {
  font-family: 'Bio Sans', sans-serif;
  font-size: 18px;
  color: #8898a7;
  text-align: center;
}

.image-container {
  padding: 5px;
  
  /* Optional padding for the image container */
}
.image-container img {
  display: block;
  max-width: 100%;
  height: auto;
}
.button-container {
  display: flex;
  justify-content: center;
  max-height: 100vh;
}

.custom-button {
  background-color: #4aa6ed;
  font-family: 'Bio Sans', sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  text-transform: uppercase; /* Capitalize the text */

  border: none;
  border-radius: 10px; /* Adjust the value to control the roundness */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  height: fit-content;
  width: 150px;
}


.custom-button:hover {
  background-color: #388dcd;
  transform: scale(1.05);
}

.custom-button:focus {
  outline: none;
}

.register-button {
  background-color: #14eb0d;
  font-family: 'Bio Sans', sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  text-transform: uppercase; /* Capitalize the text */

  border: none;
  border-radius: 10px; /* Adjust the value to control the roundness */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  height: fit-content;
  width: 150px;
}

/* .register-button:hover {
  background-color: #07fcfc;
  transform: scale(1.05);
}

.register-button:focus {
  outline: none;
} */

.company-name {
  font-family: 'Inter', sans-serif;
  font-weight: 500; /* Medium font weight */
  font-size: 32px;
  color: black;
  text-align: center;
  margin-top: 30px;
  /* position: fixed;
  bottom: 10px; */
  font-style: italic; 
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center; /* Add this line to center the content vertically */
  max-height: 100vh;
}

.register-link {
  font-family: 'Bio Sans', sans-serif;
  font-size: 18px;
  color: #8898a7;
  margin-top: 10px;
  font-style: italic;
  border: none; /* Remove the border */
  background-color: transparent; /* Remove the background */
  text-decoration: underline; /* Add underline */
}

.forgot-password{
  font-family: 'Bio Sans', sans-serif;
  color: #8898a7;
  font-style: italic;
  border: none; /* Remove the border */
  background-color: transparent; /* Remove the background */
  text-decoration: underline;

}

.forgot-password-button
{
  color: #8898a7;
  border: none; /* Remove the border */
  background-color: transparent; /* Remove the background */
}